.wrapper {
  z-index: 18;
  display: flex;
  flex-wrap: wrap;
}

.container {
  display: flex;
  align-items: center;
  margin: 10px 30px 10px 0;
}
.dateTitle {
  margin-right: 10px;
}
