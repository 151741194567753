* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  font-family: 'Helvetica Neue', Helvetica, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

svg {
  overflow: unset;
}

/*  ImageUploader  */
.fileContainer {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.fileContainer .uploadPictureContainer {
  width: 200px;
  height: auto;
  margin: 20px;
  background: transparent;
  box-shadow: none;
}

.fileContainer .deleteImage {
  top: 0;
  right: 0;
  background: none;
  color: #000;
  font-size: 14px;
  line-height: 20px;
  width: 20px;
  height: 20px;
}

.uploadPicturesWrapper > div {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.react-datepicker-popper {
  z-index: 999;
}
.react-datepicker__input-container input {
  color: #fff;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  cursor: pointer;
  border-radius: 4px;
  color: #333;
  margin-right: 6px;
  outline: 0;
}
