.form {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
}

.input {
  width: 250px;
  padding-right: 10px;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.reset {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  position: relative;
  transition: 0.3s ease-out;
  margin-left: 10px;
}
.reset:hover {
  background-color: #ccc;
}

.reset::after,
.reset::before {
  content: '';
  width: 14px;
  height: 2px;
  background-color: #666;
  position: absolute;
  top: 50%;
  left: 50%;
}

.reset::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.reset::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
