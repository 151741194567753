.table-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  position: relative;
}

.loadMoreButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
