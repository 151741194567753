.title {
  padding-bottom: 9px;
  margin: 10px 0 40px;
  border-bottom: 1px solid #eee;
  font-size: 36px;
  color: #333;
}

.actions {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.exportButton {
  height: 34px;
  padding: 0 !important;
}

.exportButton a {
  text-decoration: none;
  color: #fff;
  padding: 4px 10px;
}

.statisticContainer {
  margin-bottom: 50px;
  max-width: 1440px;
}

.statisticHeading {
  font-weight: bold;
}

.statisticText {
  margin-top: 10px;
}
