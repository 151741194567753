.wrapper {
  height: 100vh;
}

.body {
  display: flex;
  height: calc(100% - 51px);
}

.content {
  width: calc(100% - 250px);
  padding: 30px;
  overflow: auto;
}
