.title {
  padding-bottom: 9px;
  margin: 10px 0 40px;
  border-bottom: 1px solid #eee;
  font-size: 36px;
  color: #333;
}

.buttons {
  margin-right: 30px;
}

.button {
  color: #fff;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  cursor: pointer;
  border-radius: 4px;
  color: #333;
  margin-right: 6px;
  outline: 0;
}

.button:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}

.button:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.button:disabled {
  color: #bbb;
  border-color: rgba(118, 118, 118, 0.3);
  cursor: not-allowed;
}

.button:disabled:hover {
  border-color: rgba(118, 118, 118, 0.3);
  background-color: transparent;
}

.resolve {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.resolve:hover {
  background-color: #c9302c;
  border-color: #ac2925;
}

.resolve:disabled {
  color: #fff;
  border-color: rgba(172, 41, 37, 0.1);
  background-color: rgba(201, 48, 44, 0.65);
  cursor: not-allowed;
}

.resolve:disabled:hover {
  border-color: rgba(172, 41, 37, 0.1);
  background-color: rgba(201, 48, 44, 0.65);
}

.action {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
