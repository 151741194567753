.label {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 14px;
}

.labelName::after {
  content: '*';
  font-size: 12px;
}
.input {
  margin-top: 8px;
  padding: 6px 12px;
  color: #555 !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  font-size: 14px;
  line-height: 1.42857143;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.input:disabled {
  cursor: not-allowed;
  background-color: #eee;
}

.input:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

textarea.input {
  height: 115px;
}

.select {
  margin-top: 8px;
  font-weight: 400;
}

.radioLabel {
  display: inline-block;
  margin-right: 20px;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  text-transform: capitalize;
}

.radio {
  margin: 4px 4px 0 0;
  line-height: normal;
}

.error {
  font-weight: normal;
  margin: 8px 0 0;
  color: #e83326;
  font-size: 14px;
}

.datePicker {
  margin-bottom: 15px;
}
