.title {
  padding-bottom: 9px;
  margin: 10px 0 40px;
  border-bottom: 1px solid #eee;
  font-size: 36px;
  color: #333;
}

.cards {
  display: flex;
  margin-bottom: 20px;
}

.card {
  max-width: 420px;
  width: 100%;
}

.card:not(:last-child) {
  margin-right: 30px;
}

.cardContent {
  background-color: #337ab7;
  border: 1px solid #337ab7;
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardLink {
  background-color: #f5f5f5;
  width: 100%;
  text-decoration: none;
  color: black;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid #ddd;
  border-top: none;
}

.icon {
  min-width: 55px;
  min-height: 70px;
  color: white;
}

.cardData {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  line-height: 1.42857143;
}

.cardDataTitle {
  font-size: 40px;
  margin: 0;
}

.cardDataDesc {
  font-size: 14px;
  margin: 0;
  font-weight: 500;
}

.charts {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
}

.legend {
  border: 2px solid #ddd;
  border-radius: 5px;
  width: 210px;
  font-size: 12px;
  height: 480px;
  margin-top: 30px;
}

.legendHeader {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.legendBody {
  max-height: 440px;
  overflow: scroll;
  padding: 5px 10px 0 10px;
}

.legendRow {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 4px 0;
}
.legendRow:hover {
  background-color: #ddd;
}
.headerItem {
  cursor: pointer;
}

.pieChartContainer {
  margin-top: 100px;
}

.pieChartLegendContainer {
  margin-top: 20px;
}

.chartTitle {
  margin-top: 70px;
  font-size: 20px;
  font-weight: 700;
  color: #333333;
}
