.title {
  padding-bottom: 9px;
  margin: 10px 0 40px;
  border-bottom: 1px solid #eee;
  font-size: 36px;
  color: #333;
}

.form {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
}

.form:not(:first-child) {
  margin-top: 20px;
}

.section {
  margin-top: 40px;
}

.sectionTitle {
  padding-bottom: 9px;
  margin: 10px 0 20px;
  border-bottom: 1px solid #eee;
  font-size: 30px;
  color: #333;
}

.label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 14px;
}

.button-submit {
  color: #fff;
  background-color: #f0ad4e;
  border: 1px solid #eea236;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  cursor: pointer;
  border-radius: 4px;
}

.button-submit:hover {
  outline: 0;
  background-color: #ec971f;
  border-color: #d58512;
}

.button-submit:active {
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.1);
}

.button-back {
  color: #fff;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  cursor: pointer;
  border-radius: 4px;
  color: #333;
  margin-right: 6px;
}

.fieldArray {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 15px;
}

.fieldTitle {
  color: #333;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  padding: 20px 15px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.1;
}

.fieldArrayBodyWrapper {
  padding: 15px;
}

.fieldArrayBody {
  position: relative;
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 15px;
}

.fieldArrayBodyTitleWrapper {
  padding-bottom: 5px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fieldArrayBodyTitle {
  font-size: 22px;
  color: #333;
}

.buttonRemove {
  background-color: transparent;
  outline: 0;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.buttonRemove:hover {
  color: #ddd;
}

.fields {
  margin-bottom: 15px;
}

.fields > label ~ label {
  margin: 0;
}

.footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.buttonAdd {
  font-size: 18px;
  font-weight: bold;
  border-radius: 3px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.checkbox {
  margin-left: 0;
  margin-bottom: 15px;
}

.parsedWrapper {
  margin-bottom: 20px;
  word-break: break-all;
}

.parsedRow {
  display: flex;
  padding: 8px;
  line-height: 1.42857143;
  border-top: 1px solid #ddd;
  color: #333;
  font-size: 14px;
}

.parsedKey {
  font-weight: bold;
  min-width: 250px;
}

.loader {
  display: block !important;
  width: 25px !important;
  height: 25px !important;
  margin: 0 auto 20px;
}

.parsedValue {
  white-space: pre-wrap;
}

.error {
  font-weight: normal;
  margin: 8px 0 0;
  color: #e83326;
  font-size: 14px;
}

.checkbox {
  cursor: pointer;
  margin-left: 0;
  color: #333;
  margin-right: 14px;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
}
