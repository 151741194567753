.header {
  width: 100%;
  padding: 15px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e7e7e7;
}

.link {
  color: #777777;
  text-decoration: none;
  font-size: 18px;
  line-height: 20px;
}

.link:hover {
  color: #5e5e5e;
}
