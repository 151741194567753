.title {
  padding-bottom: 9px;
  margin: 10px 0 40px;
  border-bottom: 1px solid #eee;
  font-size: 36px;
  color: #333;
}

.button {
  color: #fff;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  cursor: pointer;
  border-radius: 4px;
  color: #333;
  margin-right: 6px;
  outline: 0;
  margin-right: 30px;
}

.button:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}

.button:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.actions {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
