.title {
  padding-bottom: 9px;
  margin: 10px 0 40px;
  border-bottom: 1px solid #eee;
  font-size: 36px;
  color: #333;
}

.actions {
  margin-bottom: 15px;
}

.gift-photo {
  width: 150px;
  height: auto;
}
