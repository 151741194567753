.title {
  padding-bottom: 9px;
  margin: 10px 0 40px;
  border-bottom: 1px solid #eee;
  font-size: 36px;
  color: #333;
}

.form {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
}

.label {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 14px;
  margin-right: 20px;
}

.radio-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.radio-wrapper .label {
  margin-bottom: 0;
}

.button-submit {
  color: #fff;
  background-color: #f0ad4e;
  border: 1px solid #eea236;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  cursor: pointer;
  border-radius: 4px;
}

.button-submit:hover {
  outline: 0;
  background-color: #ec971f;
  border-color: #d58512;
}

.button-submit:active {
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.1);
}

.button-back {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  cursor: pointer;
  border-radius: 4px;
  color: #333;
  margin-right: 6px;
}

/* date picker */

.datepicker {
  width: 100%;
}

.datepicker > label {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 14px;
  transform: translate(0, 1.5px);
  color: #333 !important;
}

.datepicker > div {
  margin-top: 24px !important;
  padding: 2px 12px;
  color: #555 !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  font-size: 14px;
  line-height: 1.42857143;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.datepicker > div::before,
.datepicker > div::after {
  content: none;
}

.datepicker > div button {
  padding: 4px;
}

.datepicker > div button > span {
  width: 20px;
  height: 20px;
}

.checkboxContainer {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.checkbox {
  cursor: pointer;
  margin-left: 0;
  color: #333;
  margin-right: 14px;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
}

.paymentInformationHeader {
  display: flex;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 18px;
}

.paymentInformationTitle {
  display: flex;
  margin-bottom: 6px;
  font-weight: 700;
  font-size: 14px;
}

.paymentInformationContainer > label {
  width: 100%;
}
