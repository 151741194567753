.navbar {
  width: 250px;
  height: 100%;
  background-color: #f8f8f8;
  border-right: 1px solid #e7e7e7;
  display: flex;
  flex-direction: column;
  line-height: 1.3;
}

.icon {
  max-width: 18px;
  min-width: 18px;
  margin-right: 8px;
}

.link {
  border-bottom: 1px solid #e7e7e7;
  text-decoration: none;
  padding: 10px 15px;
  color: #337ab7;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.link:hover {
  background-color: #eee;
  color: #23527c;
}

.active {
  background-color: #eee;
}
