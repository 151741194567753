.wrapper {
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: #f8f8f8;
}

.panel {
  margin-top: 100px;
  width: 100%;
  max-width: 380px;
  height: max-content;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.header {
  padding: 10px 15px;
  border-bottom: 1px solid #dddddd;
  background-color: #f5f5f5;
  color: #333333;
  font-weight: 500;
  line-height: 1.1;
}

.form {
  padding: 15px;
}

.input {
  margin-bottom: 15px;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.input:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.button {
  cursor: pointer;
  color: #fff;
  background-color: #5bc0de;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42;
  border: 1px solid #46b8da;
  border-radius: 4px;
  touch-action: manipulation;
}

.button:hover {
  background-color: #31b0d5;
  border-color: #269abc;
}

.button:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.button:active,
.button:focus {
  outline: 0;
}
